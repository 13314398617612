import React from 'react';
import { DialogTitle } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    centered: {
        justifyContent: 'center',
    },
    iconContainer: {
        display: 'inline-block',
        padding: '0 10px 3px 0',
    },
}));

export type ModalTitleProps = {
    icon?: JSX.Element;
    label?: string;
    labelParams?: string[];
};

const ModalTitle = ({ icon: IconComponent, label, labelParams = [] }: ModalTitleProps) => {
    const { classes } = useStyles();
    const t = useTranslation();

    return (
        <DialogTitle className={(!IconComponent && classes.centered) || undefined}>
            {IconComponent && <div className={classes.iconContainer}>{IconComponent}</div>}
            {label && t.trans(label, labelParams)}
        </DialogTitle>
    );
};

export default ModalTitle;
